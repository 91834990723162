<template>
    <div class="home">
        <Row>
            <Form style="display:flex;justify-content:end" ref="formInline" :model="fromData" inline :label-width="10" label-position="right">
                <FormItem>
                    <DatePicker @on-change="fromData.startTime = $event" :value="fromData.startTime" format="yyyy-MM-dd" type="date" placeholder="开始时间" style="width: 180px">
                    </DatePicker>
                </FormItem>
                <FormItem>
                    <DatePicker @on-change="onChangeEndTime" :value="fromData.endTime" format="yyyy-MM-dd" type="date" placeholder="结束时间" style="width: 180px">
                    </DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="fromData.orderType" placeholder="展示方式" style="width:140px">
                        <Option :value="item.value" v-for="(item, index) in DisplayTypeDataList" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <Button type="primary" shape="circle" icon="ios-search" @click="searchList()">搜索
                </Button>
            </Form>
        </Row>
        <div class="content">
            <div class="echart-box" id="E_zkcl" :style="
          `width:100%;height:600px;zoom:${zoom};transform:scale(${1 /
            zoom});transform-origin:0 0`
        "></div>
        </div>
    </div>
</template>
<script>
import { getUnCivilizedDataStatistics } from '@/api/ststistics/index.js'
export default {
    data() {
        return {
            DisplayTypeDataList: [{
                    value: 'asc',
                    label: '升序'
                },
                {
                    value: 'desc',
                    label: '降序'
                }
            ],
            model1: '',
            fromData: {
                startTime: this.formatDate(-30) + '',
                endTime: this.formatDate(0) + ' 23:59:59',
                orderType: 'asc',
                orderBy: 'total',
            },
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: [
                        '全部',
                        '反复阻挡门',
                        '电动车入梯',
                        '全部(比率)',
                        '反复阻挡门(比率)',
                        '电动车入梯(比率)'
                    ],
                    selected: {
                        全部: true,
                        反复阻挡门: false,
                        电动车入梯: false,
                        '全部(比率)': true,
                        '反复阻挡门(比率)': false,
                        '电动车入梯(比率)': false
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: ['外围分布', '瑞金总院'],

                    axisLabel: {
                        interval: 0,
                        rotate: 40
                    }
                }],
                yAxis: [{
                        type: 'value',
                        name: '',
                        min: 0,
                        // max: 250,
                        interval: 50,
                        axisLabel: {}
                    },
                    {
                        type: 'value',
                        name: '',
                        min: 0,
                        // max: 25,
                        // interval: 5,
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    }
                ],
                series: [{
                        name: '全部',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function(value) {
                                return value + ' ml'
                            }
                        },
                        data: [6, 124]
                    },
                    {
                        name: '急修',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function(value) {
                                return value + ' ml'
                            }
                        },
                        data: [1, 13]
                    },
                    {
                        name: '困人',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function(value) {
                                return value + ' ml'
                            }
                        },
                        data: [0, 13]
                    },
                    {
                        name: '全部(比率)',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function(value) {
                                return value + ' °C'
                            }
                        },
                        data: [0.00203, 0.0101]
                    },
                    {
                        name: '急修(比率)',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function(value) {
                                return value + ' °C'
                            }
                        },
                        data: [0.00017, 0.01772]
                    },
                    {
                        name: '困人(比率)',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function(value) {
                                return value + ' °C'
                            }
                        },
                        data: [0, 0.01773]
                    }
                ]
            },
            zoom: 1
        }
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        }
    },
    mounted() {
        const width =
            document.documentElement.clientWidth || document.body.clientWidth
        this.zoom = 1 / (width / 1920)
        window.addEventListener('resize', () => {
            const width =
                document.documentElement.clientWidth || document.body.clientWidth
            this.zoom = 1 / (width / 1920)
        })
        this.searchList()
    },
    watch: {
        'fromData.orderBy'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.searchList()
            }
        },
        'theme'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.searchList()
            }
        }
    },
    methods: {
        searchList() {
            getUnCivilizedDataStatistics(this.fromData).then(res => {
                let myChart = this.$echarts.init(document.getElementById('E_zkcl'))
                if (res.info) {
                    // this.option.xAxis[0].data = res.info.categories
                    this.option.xAxis[0].data = res.info.categories.map((item) => {
                        return {
                            value: item,
                            textStyle: {
                                color: this.theme == 'dark' ? '#fff' : ''
                            }
                        }
                    })
                    let arr = []
                    let arr2 = []

                    res.info.series.forEach(item => {
                        arr.push(item.name)
                        if (item.type == 'column') {
                            arr2.push({
                                name: item.name,
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function(value) {
                                        return value
                                    }
                                },
                                data: item.data
                            })
                        } else {
                            arr2.push({
                                name: item.name,
                                type: 'line',
                                tooltip: {
                                    valueFormatter: function(value) {
                                        return value
                                    }
                                },
                                yAxisIndex: 1,
                                data: item.data
                            })
                        }
                    })
                    this.option.legend.data = arr
                    this.option.series = arr2
                }
                this.option.yAxis.forEach((item) => {
                    item.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
                })

                myChart.setOption(this.option)
                let that = this
                myChart.on('legendselectchanged', function(params) {
                    if (params.name == '全部' || params.name == '全部(比率)') {
                        that.option.legend.selected['全部'] = true
                        that.option.legend.selected['反复阻挡门'] = false
                        that.option.legend.selected['电动车入梯'] = false
                        that.option.legend.selected['全部(比率)'] = true
                        that.option.legend.selected['反复阻挡门(比率)'] = false
                        that.option.legend.selected['电动车入梯(比率)'] = false
                        that.fromData.orderBy = "total"
                    }

                    if (params.name == '反复阻挡门' || params.name == '反复阻挡门(比率)') {
                        that.option.legend.selected['全部'] = false
                        that.option.legend.selected['反复阻挡门'] = true
                        that.option.legend.selected['电动车入梯'] = false
                        that.option.legend.selected['全部(比率)'] = false
                        that.option.legend.selected['反复阻挡门(比率)'] = true
                        that.option.legend.selected['电动车入梯(比率)'] = false
                        that.fromData.orderBy = "blockDoor"
                    }

                    if (params.name == '电动车入梯' || params.name == '电动车入梯(比率)') {
                        that.option.legend.selected['全部'] = false
                        that.option.legend.selected['反复阻挡门'] = false
                        that.option.legend.selected['电动车入梯'] = true
                        that.option.legend.selected['全部(比率)'] = false
                        that.option.legend.selected['反复阻挡门(比率)'] = false
                        that.option.legend.selected['电动车入梯(比率)'] = true
                        that.fromData.orderBy = "electricBike"
                    }

                    myChart.hideLoading()
                    myChart.setOption(that.option)
                })
            })
        },
        onChangeEndTime(e) {
            this.fromData.endTime = e + ' 23:59:59'
        }
    }
}
</script>
<style scoped>
.home {
    padding: 10px 30px 0 10px;
}
</style>